@import '../../assets/css/variabless.scss';

.login-page {
    background: transparent !important;
}
.login-page  .text-content .content li{
    font-size:16px;
    margin-bottom: 20px;
}
.login-page .form-block form .form-control{
    background: $clr-bg-input;
    border-radius: 5px;
    line-height: 25px;
    padding-left: 17px;
    width: 385px;
    height: 43px;
    margin: 0 auto;
    font-family: $ff-text-main;
    color: $clr-text-no-active;
    font-size: $fz-mob-small;
}
input .form-control{
    position: relative;
}
.icon-login{
    font-size: 15px;
    color: $clr-text-active;
    position: absolute;
    top: 337px;
    left: 8%;
}
.icon-login-after{
    top: 387px;
}
.login-page .form-block form .form-control::placeholder{
    font-style: normal;
    font-weight: normal;
    font-family: $ff-text-main;
    color: $clr-text-no-active;
    font-size: $fz-mob-main;
}
.login-page.registration-page .form-block form .form-control{
    background: $clr-bg-input;
    border-radius: 5px;
    line-height: 25px;
    padding: 20px;
    font-size: $fz-mob-main;
    font-weight: $fw-400; 
    font-family: $ff-text-main;
    color: $clr-text-no-active;
}
.login-page .form-block .form-block form .form-control::placeholder{
    font-weight: $fw-400; 
    font-family: $ff-text-main;
    color: $clr-text-no-active;
}
.login-page.registration-page .form-block form .form-control::placeholder{
    font-weight: $fw-400; 
    font-family: $ff-text-main;
    color: $clr-text-no-active;
}
.form-block form{
    background: transparent !important;
}
.login-page .form-block form .form-group{
    margin-bottom: 6px;
}
.form-group {
    width: 385px;
    margin: 0 auto;
}
.form-group button{
    font-family: $ff-text-main;
    font-style: normal;
    font-weight: $fw-700;
    font-size: $fz-mob-small;
    line-height: 34px;
    text-align: center;
    border-radius: 5px;
    width: 385px;
    height: 53px;
    background: $clr-bg-btn;
    border: none;
    margin: 0 auto;
}
.login-page .or-block span{
    display: inline-block;
    padding: 20px;
    background: #fff;
}
.login-page .or-block hr{
    margin-top:30px;
    margin-bottom:30px;
    border-top-width: 2px;

}
.login-page .or-block .text-block{
    position: absolute;
    top:-30px;
    width: 100%;
}
.login-page.registration-page  .text-content .content{
    padding-bottom:500px ;
}

.login-page.registration-page .form-block .registration-nav{
    position: absolute;
    bottom:-70px;
    text-align: center;
    width: 100%;
}
.login-page.registration-page .form-block .registration-nav ul{
    padding: 0;
}
.login-page.registration-page .form-block .registration-nav ul li{
    display: inline-block;
    padding: 4px;
}
.login-page.registration-page .form-block .registration-nav ul li .circle{
    width: 10px;
    height: 10px;
    background: #000;
    display: inline-block;
    opacity: 0.4;
    border-radius: 50%;
}

.login-page.registration-page .form-block .registration-nav ul li.active .circle{
    opacity: 1;
}
.registration-btn{
    display: flex;
    justify-content: space-between;
    &-back{
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border-radius: 12px;
        border: 1px solid #FFFFFF;
    }
    &-next{
        width: 341px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #50D5FF 0%, rgba(80, 213, 255, 0) 100%), #1448FF;
        border-radius: 10px;
        border: none;
        font-family: "Nunito";
        font-size: 15px;
        color: #FFFFFF;
    }
}
.position-absolut-btn-next{
    position: absolute;
    top: 93%;
    left: 0;
    right: 0;
}
@media (min-width: 992px){
    .login-page .form-block{
        width: 100vw;
        height: 100vh;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        position: fixed;
        right: 0;
        top: 0;
        background: $clr-bg;
    }
    .login-page .registration-page .form-block{
        left:calc(50% - 200px);
    }
    .login-page .text-content{
        position: absolute;
        width: 100%;
        top:180px;
    }

}
@media (max-width: 991px){
    .login-page{
        background: $clr-bg;
        max-width: 991px;
        width: 100%;
        height: 896px;
    }
    .login-page .images-block{
        display: none;
    }
    .login-page .text-content{
        display: none;
    }
}
// ----registration
// login-page   position-relative
// text-content
// row
// col-lg-7 content
// form-block
// registration-nav

// ----registration1
// birthday-title birthday-title-margin-top
// form-group
// error
// form-control
// errors-messages
// form-group row position-absolut-btn-next
// registration-btn
// registration-btn-back
// registration-btn-next


// ----registration2
// title-firstName-lastName
// form-group
// error
// form-control
// errors-messages
// title-firstName-lastName
// bottom-input-text
// form-group row position-absolut-btn-next
// registration-btn
// registration-btn-back
// registration-btn-next

// ----registration4
// form-group registration-avatar-uploader-block
// error
// errors-messages
// title-firstName-lastName
// form-group form-group-reg4
// form-control
// bottom-input-text
// form-group row position-absolut-btn-next
// registration-btn
// registration-btn-back
// egistration-btn-next

// ----registrationThankYou
// py-3
// container
// row
// col-6