@import '../../assets/css/variabless.scss';

.logo{
    margin-top: 203px;
    &-title{
        font-size: $fz-logo;
        color: $clr-logo;
        font-family: $ff-logo;
        font-weight: $fw-400;
        text-align: center;
    }
    &-subtitle{
        font-size: $fz-mob-main;
        color: $clr-logo;
        font-family: $ff-text-main;
        margin-top: -15px;
        text-align: center;
    }
}