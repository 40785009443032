$clr-bg: (linear-gradient(180deg, #222222 0%, rgba(34, 34, 34, 0) 100%), #000000);
$clr-bg-btn: (linear-gradient(180deg, #50D5FF 0%, rgba(80, 213, 255, 0) 100%), #1448FF);
$clr-bg-input: rgba(255, 255, 255, 0.03);
$clr-black: #000;
$clr-logo: #FFF;
$clr-text-active: #FFF;
$clr-text-no-active: rgba(255, 255, 255, 0.5);
$clr-link: #1448FF;
$clr-old-link: #007bff;
$clr-old-bg-btn: #2C78EB;
$fz-logo: 55px;
$fz-mob-small: 15px;
$fz-mob-main: 18px;
$fw-400: 400;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$ff-logo: 'Pacifico', cursive;
$ff-text-main: 'Nunito', sans-serif;
$ff-text-main-sub: 'Nunito Sans', sans-serif;
$ff-icons: "Ionicons";