@import '../../assets/css/variabless.scss';

.feed-context-menu{
    position: fixed;
    bottom: 28px;
    left:0;
    min-width: 414px;
    height: 206px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
}
.feed-context-menu div{
    background-color: $clr-black;
    border-radius: 12px;
    width: 94.2%;
}
.feed-context-menu div ul{
    list-style-type: none;
    width: 100%;
    height: 150px;
    border-radius: 12px;
    background: $clr-bg;
    padding: 0;

}
.feed-context-menu div ul li{
    padding: 17.92px 18.92px;
    width: 100%;
    height: 49px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}
.feed-context-menu div ul li a{
    width: 100%;
    font-family: $ff-text-main-sub;
    font-weight: $fw-600;
    font-size: $fz-mob-main !important;
    color: $clr-text-active !important;
}
.feed-context-menu div ul li a img{
    margin-right: 23.69px;
}
.feedContext-btn{
    width: 100%;
    height: 43px;
    background-color: #1448FF;
    border-radius: 10px;
    border: none;
    font-family: $ff-text-main;
    font-weight: $fw-700;
    font-size: $fz-mob-small;
    color: $clr-text-active;
}

.feed-context-menu ul li a.danger{
    color:#f00 !important;
}
.report-modal{
    font-size: 19px ;
    font-weight: 600;
}
.report-modal label{
    font-weight: 600;
}
.report-modal textarea{
    font-size: 19px;
}
.report-modal textarea::placeholder{
    color: $clr-text-no-active;
}
