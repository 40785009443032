.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.conversation-list-item:hover, .conversation-list-item.active {
  background: #007aff;
  cursor: pointer;
  color:#fff;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}
.conversation-info{
  max-width: 100%;
  overflow: hidden;
}
