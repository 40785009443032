.messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 100px);
  background: rgba(0, 0, 0, 0.5);
  grid-template-columns: 320px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  z-index: 1;
}

.container {
  padding: 0px;
  /* padding: 10px; */
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: #212121;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  height: 100%;
  background: #000;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: #000;
}

  .messenger.hide-sidebar {
    grid-template-columns: 0px auto;
  }
  .messenger.hide-sidebar .conversation-list .toolbar-title{
    display: none;
  }
  .messenger.hide-sidebar .conversation-list .left-items{
    display: none;
  }
  .messenger.hide-sidebar .conversation-list .right-items{
    justify-content: center;
  }
  .messenger.hide-sidebar .conversation-list .conversation-search{
    display: none;

  }
  .messenger.hide-sidebar .conversation-list .conversation-list-item{
    display: none;
  }

@media (max-width:767px){
  .messenger{
    display: flex;
    flex-direction: column;
  }
}