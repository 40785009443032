@import '../../assets/css/variabless.scss';
.gender{
    width: 385px;
    margin: 0 auto;
    &-container{
        margin: 0 auto;
        width: 385px;
        margin-top: 31px;
    }
    &-title{
        font-size: $fz-mob-small;
        font-family: $ff-text-main;
        color: $clr-text-active;
        margin-bottom: 10px;
    }
    &-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32%;
        height: 43px;
        background-color: transparent;
        color: $clr-text-active;
        border-radius: 10px;
        margin-bottom: 5px;
        box-shadow: transparent;
        border: 1px solid $clr-text-no-active;
    }
    &-btn:last-child{
        width: 100%;
        margin-bottom: 166px;
    }
    &-btn input{
        display: none;
    }
}
@media (max-width: 768px) {
    .login-page .form-block form .gender-form{
        padding: 0px !important;
    }
}