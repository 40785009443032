@import '../../assets/css/variabless.scss';

.form-picker{
  margin: 29px auto 0;
  width: 385px;
}

.birthday{
  display: flex;
}
.birthday-title{
  width: 385px;
  color: $clr-text-active;
  margin: 0 auto 10px;
  font-size: $fz-mob-small;
  font-family: $ff-text-main;
  padding: 0 !important;
}
.birthday select{
  height: 43px;
  padding-left: 17px;
  border-radius: 5px;
  border: 1px solid $clr-text-active;
  color: $clr-text-active;
  background-color: transparent;
  font-size: $fz-mob-small;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
}
.birthday select option{
  color: $clr-text-active;
  background-color: black;
}
.birthday-month{
  width: 126px;
}
.birthday-day{
  width: 82px;
  position: relative;
  display: inline-block;
  margin: 0 6px;
}
.birthday-year{
  width: 105px;
  position: relative;
  display: inline-block;
}
@media (max-width: 425px) {
  .birthday-around{
      flex-direction: column;
  }
  .birthday select:nth-child(2){
      width: 100%;
  }
  .birthday select:nth-child(3){
      width: 100%;
  }
}
@media (max-width: 768px) {
  .login-page .form-block form .birthday {
      padding: 0px !important;
  }
}