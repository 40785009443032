@import '../../assets/css/variabless.scss';

.category{
    &-header-btn{
        width: 153px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $clr-text-active;
        background-color: $clr-old-bg-btn;
        border: none;
        border-radius: 12px;
        font-size: $fz-mob-main;
        font-family: $ff-text-main;
    }
    &-header-btn:hover{
        background-color: $clr-old-link;
    }
    &-container{
        width: 385px;
        margin: 0 auto;
    }
    &-title{
        font-size: 21px;
        font-family: $ff-text-main;
        color: $clr-text-active;
        text-align: center;
    }
    &-form{
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5.5px
    }
    &-btn{
        width: 121px;
        height: 121px;
        background-color: transparent;
        color: $clr-text-active;
        border-radius: 10px;
        margin-bottom: 5px;
        box-shadow: transparent;
        border: none;
        
    }
    &-btn input{
        display: none;
    }
    &-btn-around{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 121px;
        height: 121px;
    }
    &-btn-up{
        width: 123px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-btn-down{
        width: 123px;
        height: 38px;
    }
    &-footer-btn{
        width: 385px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $clr-text-active;
        position: absolute;
        bottom: 28px;
        left: auto;
        right: auto;
        background-color: $clr-old-bg-btn;
        border: none;
        border-radius: 12px;
    }
    &-footer-btn:hover{
        background-color: $clr-old-link;
    }
}